<template>
    <div class="home">
        <div class="top flex-cc">
            <img class="logo" @click="goHome('')" src="@/assets/mobile/logo@2x.png" alt="">
            <div class="flex">
                <div @click="active = 1" :class="[active == 1 ? 'nav active':'nav']">关于赛事<span v-if="active == 1"></span></div>
                <div @click="active = 2" :class="[active == 2 ? 'nav active':'nav']">赛事赛制<span v-if="active == 2"></span></div>
                <div @click="active = 3" :class="[active == 3 ? 'nav active':'nav']">评审介绍<span v-if="active == 3"></span></div>
            </div>
        </div>
        <div v-if="active == 1">
            <div class="part1">
                <div class="title flex-vc"><span></span>物语常熟文化创意设计大赛</div>
                <div class="line"></div>
                <img class="kv" src="@/assets/about/kv@2x.png" alt="">
                <div class="content">
                    本次赛事致力于聚焦常熟文旅资源，打造常熟文化名片。赛事主要面向全国征集优秀文创设计作品，为提升常熟文化旅游聚集智慧。以提升常熟文创内涵及产品品质为基础，使创意设计与当地文旅产业需求相结合，开发创新一批承载常熟地域故事的文化创意产品，助推常熟文化旅游产业提档升级、促进常熟文化旅游高质量发展。
                </div>
            </div>
            <div class="part2">
                <img src="@/assets/about/pic1@2x.png" alt="">
                <img src="@/assets/about/pic2@2x.png" alt="">
                <img src="@/assets/about/pic3@2x.png" alt="">
                <img src="@/assets/about/pic4@2x.png" alt="">
            </div>
        </div>
        <div v-if="active == 2">
            <div class="part1">
                <div class="title flex-vc"><span></span>赛事赛道</div>
                <div class="line"></div>
                <div class="content">
                    征集方向围绕常熟悠久历史背景，民族风情、地标建筑，知名景区，美食特产、非物质文化遗产等特色文化旅游资源等元素进行创意设计。作品要具有较高的创新性、艺术性及实用性，符合市场需求，适宜大众消费，体现“艺术生活化、生活艺术化”发展趋势。
                </div>
            </div>
            <div class="part2-2">
                <div class="line"></div>
                <div class="flex-vc" style="margin-bottom: .48rem" v-for="(item,index) in cardList" :key="index" @click="model(index)">
                    <img class="icon" src="@/assets/about/icon@2x.png" alt="">
                    <div class="card flex" >
                        <div class="ip-wrap flex-cc">
                            <img :src="item.src" alt="">
                
                        </div>
                        <div class="right-wrap">
                            <div class="name"><span>{{item.order}}</span>{{item.name}}</div>
                                <div class="info" v-for="(iitem,iindex) in item.info" :key="iindex">{{iitem}}</div>
                        </div>
                        <img class="more" src="@/assets/about/more@2x.png" alt="">
                    </div>
  
                </div>
            </div>
        </div>
        <div v-if="active == 3">
            <div class="part1">
                <div class="title flex-vc"><span></span>评审介绍</div>
                <div class="line"></div>
                <div class="part3-1 flex-wrap">
                    <div class="peocard" v-for="(item,index) in peopleList" :key="index">
                        <img :src="item.src" alt="">
                        <div class="name">{{item.name}}</div>
                        <div class="position">{{item.position}}</div>
                        <div class="info" v-for="(iitem,iindex) in item.info" :key="iindex">{{iitem}}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="model-wrap" v-if="showmodel">
            <img @click="showmodel = false" class="close" src="@/assets/about/close@2x.png" alt="">
            <div class="writebg">
                <div v-if="saidao == 1">
                    <div class="title1">赛道一 <span style="font-weight: 400; margin-left: .2rem">常熟文创形象“虞小主”卡通形象设计</span></div>
                    <div class="title2">
                        垆边人似月，皓腕凝霜雪
                    </div>
                    <div class="content">
                        抿唇一笑，温柔了岁月。扶柳分花，点缀了时光。<br>
                        她芳龄20，来自常熟；她有着江南的灵秀之气，又是英姿飒爽的“小主”；她的美如同虞山之美，越看越养眼；她知书达理，但精灵古怪；她亦动亦静，温顺中略带刁蛮，乖顺中时不时会闯点小祸，她就是虞小主。
                    </div>
                    <div class="title1">参赛者根据以下要求进行设计</div>
                    <div class="content">
                        ①方案中须有“虞小主”卡通形象三视图，包含：正面、侧面、背面，并设计三个或三个以上能表现该形象特色的动作或表情（萌化表情及肢体动作等）、变装；<br>
                        ②方案中须配上必要的文字说明，包括：形象姓名、性别等基本信息，作品创作思路或设计说明简述等；<br>
                        ③方案中须有“虞小主”动漫形象的场景应用，比如古装、马拉松选手、城市白领等等。<br>
                        ④可有但不限于“虞小主”形象的文创周边设计和在公共设施&产品延展<br>
                        ⑤请将设计图、效果图、介绍文字等整合后以方案形式进行呈现，文件大小不大于30M，文件格式为PDF格式
                    </div>
                </div>
                <div v-if="saidao == 2">
                    <div class="title1">赛道二 <span style="font-weight: 400; margin-left: .2rem">常熟大米产品包装设计</span></div>
                    <div class="title2">水满田畴稻叶齐，日光穿树晓烟低</div>
                    <div class="content">
                        常熟地处江南福地，因年年丰收而得名。“常熟大米”获国家地理标志证明商标，“常优1号”还曾入选国家星火计划，常熟大米已经在全国累计推广约7000万亩，为国家增产粮食20.6亿公斤。优质的常熟大米，以“软、糯、香”名闻遐迩。<br>
                        在常熟潜心钻研水稻的代表人物是端木银熙——常熟市农业科学研究所推广研究员，水稻育种专家，端木银熙致力于水稻育种42年，从来没有离开过脚下的土地。
                    </div>
                    <div class="title1">参赛者根据以下要求进行设计</div>
                     <!-- ①、②、③、④、⑤、⑥、⑦、⑧、⑨、⑩  -->
                    <div class="content">
                        ①从开启体验层面（视觉/外观/包装手感等）入手，针对年轻消费者群体，结合一人食份及多人组合的大米消费场景，以及消费者心理、行为习惯，洞察消费者需求，在便于使用的基础上，打造全新的消费体验。<br>
                        ②san请为作品配上必要的文字说明，包括：使用场景、包装方式以及包装尺寸规格等基本信息，作品创作思路或设计说明简述等作品，要求视觉表达创新，材质搭配精妙，细节品质突出。<br>
                        ③作品要考虑其实际生产材质和造型等因素上的可实现性，符合当前旅游者的需求和消费水平，并具有实用价值。旅游商品应具备批量生产及市场推广价值，便于携带<br>
                        ④作品鼓励使用生态环保材料及运用环保理念进行创新设计<br>
                        ⑤内容包含但不限于米袋、包装盒、礼盒、餐具、自热包等<br>
                        ⑥请将设计图、效果图、介绍文字等整合后以方案形式进行呈现，文件大小不大于30M，文件格式为PDF格式
                    </div>
                </div>
                <div v-if="saidao == 3">
                    <div class="title1">赛道三 <span style="font-weight: 400; margin-left: .2rem">虞山碧螺春包装设计</span></div>
                    <div class="title2">戏作小诗君一笑，从来佳茗似佳人</div>
                    <div class="content">
                        虞山碧螺春是常熟人的骄傲，十里青山半入城，茶园掩映在山林间。<br>
                        鲜嫩的芽叶正是在这样的山林树木下破势而出，这就是常熟绿茶飘香江南的天赋所在。每年的春分前后，正是虞山碧螺春开采的时节，大概到谷雨结束，春分到清明采摘制作的明前茶，最为名贵。经过杀青、揉捻、做形、提香之后，一杯花香扑鼻的碧螺春就这么来了，一口下去，春光艳潋，舌根留香。
                    </div>
                    <div class="title1">参赛者根据以下要求进行设计</div>
                    <div class="content">①从开启体验层面（气味/视觉/外观/包装手感等）入手，要求袋泡茶形式，便于携带，结合年轻群体茶叶消费场景，以及消费者心理、行为习惯，洞察消费者需求，在便于使用的基础上，打造全新的消费体验。
                    ②请为作品配上必要的文字说明，包括：使用场景、包装方式及包装尺寸规格等基本信息，作品创作思路或设计说明简述等作品，要求视觉表达创新，材质搭配精妙，细节品质突出。
                    ③作品要考虑其实际生产材质和造型等因素上的可实现性，符合当前消费者的需求和消费水平，并具有实用价值。应具备批量生产及市场推广价值，便于携带
                    ④作品鼓励使用生态环保材料及运用环保理念进行创新设计
                    ⑤内容包含但不限于茶罐、内袋、包装盒、礼盒、配件等
                    ⑥请将设计图、效果图、介绍文字等整合后以方案形式进行呈现，文件大小不大于30M，文件格式为PDF格式</div>
                </div>
                <div v-if="saidao == 4">
                     <div class="title1">赛道四 <span style="font-weight: 400; margin-left: .2rem">常熟文化主题生活用品设计</span></div>
                    <div class="title2">万籁此皆寂，惟闻钟磬音。</div>
                    <div class="content">
                        常熟之美，历久弥新，如何让常熟元素进入年轻人生活场景？如何让常熟元素融入Z世代语境？
                        尽情发挥你的脑洞，创作设计出丰富的常熟文创产品体验！<br>
                        三大主题学习/生活/出行，Z世代生活全覆盖。<br>
                        A.书香常熟套装：包括但不限于笔、笔袋/盒、笔记本、书签等，要求以套装礼盒产品为目标进行设计，可根据使用者场景构想新用品加入套装当中<br>
                        B.寻味常熟套装：例如不限于杯子、水壶（随行杯）、果盘等餐具，要求以套装礼盒产品为目标进行设计，可根据使用者场景构想新用品加入套装当中<br>
                        C.创意常熟套装：例如不限于雨伞、斜挎胸包、渔夫帽等，要求以套装礼盒产品为目标进行设计，可根据使用者场景构想新用品加入套装当中
                    </div>
                    <div class="title1">参赛者根据以下要求进行设计 </div>
                    <div class="content">
                        ①限定年轻人日常生活场景，视觉上有强烈的江南文化、常熟文化画面、符号元素（虞山元素；园林、山水等江南元素；良渚文化元）素，创作应充分考虑以常熟地区传统文化、文旅景区、民俗非遗、地方美食等城市文化符号为创意题材进行设计。要求视觉与材质上有新意与创新，色彩和谐。<br>
                        ②请将设计图、效果图、介绍文字等整合后以方案形式进行呈现，文件大小不大于30M，文件格式为PDF格式<br>
                        ③作品要考虑其实际生产材质和造型等因素上的可实现性，符合当前旅游者的需求和消费水平，并具有实用价值。旅游商品应具备批量生产及市场推广价值，便于携带<br>
                        ④作品鼓励使用生态环保材料及运用环保理念进行创新设计<br>
                        ⑤要求作品在产品使用角度上具有实用性，套装内容需要在使用时互有功能逻辑，使用体验出色<br>
                        ⑥请为作品配上必要的文字说明，包括：使用场景、包装方式及包装尺寸规格等基本信息，作品创作思路或设计说明简述等作品，要求视觉表达创新，材质搭配精妙，细节品质突出。
                    </div>
                </div>
                <div v-if="saidao == 5">
                     <div class="title1">赛道五 <span style="font-weight: 400; margin-left: .2rem">无界常熟，文创物品创新设计开放命题</span></div>
                    <div class="title2">天马行空，任君驰骋</div>
                    <div class="content">
                        只要与常熟文化创意、文旅资源相关，所有的创作内容我们来者不拒<br>
                        这是给到参赛者最大自由度的设计命题，创作应充分考虑以常熟地区传统文化、文旅景区、民俗非遗、地方美食等城市文化符号为创意题材进行设计。提倡设计风格年轻化，作品表现手法不限。 <br> 
                        平面设计——不限于海报、（书籍/画册等）出版物、字库字体、创意字形、视觉实验等<br>
                        插画艺术——不限于商业插画、绘本、影视概念设定、游戏概念设定等<br>
                        数字影视——不限于数字短片 、故事动画、Motion Graphic、影视短片等<br>
                        摄影作品——不限于建筑摄影、人文/纪实摄影、时尚艺术摄影、人像/风光/美食摄影等
                    </div>
                    <div class="title1">参赛者根据以下要求进行设计</div>
                    <div class="title2">①平面/插画/包装/摄影等</div>
                    <div class="content">请将设计图、效果图、照片、介绍文字等整合后以方案形式进行呈现，文件大小不大于30M，文件格式为PDF格式</div>
                    <div class="title2">②数字影视</div>
                    <div class="content">
                        每件作品需提交不超过3条视频文档，以及体现作品的预览效果关键图电子档，<br>
                        视频文档要求：尺寸：1080p，存储格式：avi、mp4、wmv、flv等。<br>
                        电子档文件大小不大于30M，文件格式为PDF格式
                    </div>

                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            active: 1,
            cardList: [
                {
                    src: require('@/assets/about/ip@2x.png'),
                    order: '赛道一',
                    name: '常熟文创形象“虞小主”卡通形象设计',
                    info: ['垆边人似月，皓腕凝霜雪','抿唇一笑，温柔了岁月。扶柳分花，点缀了时光。','她芳龄20，来自常熟；她有着江南的…']
                },
                {
                    src: require('@/assets/about/ip2@2x.png'),
                    order: '赛道二',
                    name: '常熟大米产品再包装设计',
                    info: ['水满田畴稻叶齐，日光穿树晓烟低','常熟地处江南福地，因年年丰收而得名。“常熟大米”获国家地理标志证明商标，“常优1号”…',]
                },
                {
                    src: require('@/assets/about/ip3@2x.png'),
                    order: '赛道三',
                    name: '虞山碧螺春包装设计',
                    info: ['戏作小诗君一笑，从来佳茗似佳人 ','虞山碧螺春是常熟人的骄傲，十里青山半入城，茶园掩映在山林间。鲜嫩的芽叶正是…',]
                },
                {
                    src: require('@/assets/about/ip4@2x.png'),
                    order: '赛道四',
                    name: '常熟文化主题生活用品设计',
                    info: ['万籁此皆寂，惟闻钟磬音。 ','常熟之美，历久弥新，如何让常熟元素进入年轻人生活场景？如何让常熟元素融入Z世代…',]
                },
                {
                    src: require('@/assets/about/ip5@2x.png'),
                    order: '赛道五',
                    name: '无界常熟，文创物品创新设计开放命题',
                    info: ['天马行空，任君驰骋  ','只要与常熟文化创意、文旅资源相关，所有的创作内容我们来者不拒…',]
                },
            ],
            showmodel: false,
            peopleList: [
                {
                src: require('@/assets/home2/peo01@2x.png'),
                name: '祁 林',
                position: '(赛事顾问)',
                info: ['南京大学艺术学院教授、' ,'南京大学出版社副主编、' ,'教育部创新创业教指委委员', '中国高等院校影视学会理事、', '中国高等院校影视学会网络视听协会副主任委员']
                },
                {
                src: require('@/assets/home2/peo02@2x.png'),
                name: '李守白',
                position: '(赛事评审)',
                info: ['上海市文联副主席、' ,'上海民协主席' ,' 非遗“海派剪纸”代表性传承人、']
                },        
                {
                src: require('@/assets/home2/peo03@2x.png'),
                name: '张黎明',
                position: '(赛事评审)',
                info: ['上海市非物质文化遗产保护中心办公室主任' ,'上海市非物质文化遗产专家委员会委员']
                },
                {
                src: require('@/assets/home2/peo04@2x.png'),
                name: '邵 刚',
                position: '(赛事评审)',
                info: ['茶产业赋能平台【茶能社】' ,'文创品牌【绎匠研创】创始人 ','国家高级评茶师']
                },
                {
                src: require('@/assets/home2/peo05@2x.png'),
                name: '姚 诞',
                position: '(赛事评审)',
                info: ['原上海工艺美术职业学院' ,'工艺美术研究中心主任 ']
                },
                {
                src: require('@/assets/home2/peo06@2x.png'),
                name: '大众评委',
                position: '',
                info: ['将与赛事评审一同进行评选工作' ,'评审阶段于线上进行投票 ']
                },
            ],
            saidao: ''
        }
    },
    methods: {
        model(index) {
            this.saidao = index+1;
            this.showmodel = true;
        }
    }
}
</script>
<style lang="less" scoped>
.flex {
    display: flex;
}
.flex-cc {
    display: flex;
    align-items: center;
    justify-content: center;
}
.flex-vc {
    display: flex;
    align-items: center;
}
.flex-wrap {
    display: flex;
    flex-wrap: wrap;
}
.home {
    background: #F7F7F7;
    min-height: 100vh;
    .top {
        width: 6.86rem;
        height: .92rem;
        background: #fff;
        padding: .32rem;
        .logo {
            width: 2rem;
            display: block;
            text-align: left;
            margin-right: .1rem;
            
        }
        .nav {
            font-size: .28rem;
            color: #999999;
            margin-left:  .36rem;
            line-height: .44rem;
        }
        .active {
            font-size: .32rem;
            color: #45B79C;
            font-weight: bold;
            span {
                width: .6rem;
                height: .04rem;
                transform: scaleY(-1);
                background: #45B79C;
                border-radius: .04rem .04rem 0 0;
                display: block;
                margin: 0 auto;
            }
        }
    }
    .part1 {
        margin-top: .2rem;
        background: #fff;
        width: 7.5rem;
        padding-bottom: .01rem;
        // height: 8.2rem;
        .title {
            font-size: .3rem;
            color: #333333;
            font-weight: bold;
            line-height: 1rem;
            span {
                width: .04rem;
                height: .28rem;
                background: #333333;
                border-radius: 0 .03rem .03rem 0;
                display: block;
                margin-right: .28rem;
            }
        }
        .line {
            width: 6.86rem;
            height: .02rem;
            background: #EBEBEB;
            margin: 0 auto;
        }
        .kv {
            width: 6.86rem;
            margin-top: .28rem;
        }
        .content {
            font-size: .26rem;
            color: #666666;
            line-height: .44rem;
            width: 6.86rem;
            margin: .24rem auto;
            text-align: left;

        }
    }
    .part2 {
        width: 7.5rem;
        // height: 4.32rem;
        background: #fff;
        margin-top: .2rem;
        padding-top: .7rem;
        img {
            // 3.88 2.16
            width: 3.88rem;
            height: 2.16rem;
            margin-top: -.45rem;
            margin-right: -.15rem;
            margin-left: -.15rem;

        }
    }
    .part2-2 {
        width: 7.5rem;
        background: #fff;
        margin-top: .2rem;
        padding-top: .28rem;
        position: relative;
        .line {
            height: 8.14rem;
            width: .02rem;
            background: #45B79C;
            position: absolute;
            top: 1rem;
            left: .32rem;
        }
        .icon {
            width: .36rem;
            height: .36rem;
            margin: 0 .14rem;
        }
        .card {
            width: 6.54rem;
            height: 1.56rem;
            background: #FFFFFF;
            box-shadow: 0 .08rem .24rem 0 rgba(0,0,0,0.20);
            border-radius: .04rem;
            position: relative;
            .more {
                position: absolute;
                top: 1.08rem;
                left: 6.06rem;
                width: .36rem;
            }
            .ip-wrap {
                width: 1.4rem;
                height: 1.56rem;
                border-right: .02rem solid #666666;
                img {
                    width: .72rem;
                }
            }
            .right-wrap {
                padding: .16rem;
                text-align: left;
                .name {
                    font-size: .22rem;
                    color: #23242A;
                    span {
                        font-size: .28rem;
                        font-weight: bold;
                        margin-right: .2rem;
                    }
                }
                .info {
                    font-size: .2rem;
                    color: #666666;
                    width: 4.4rem;
                }
            }
  
        }
    }
    .model-wrap {
        width: 7.5rem;
        height: 100vh;
        background: rgba(0,0,0,0.50);
        position: fixed;
        top: 0;
        left: 0;
        .close {
            width: .6rem;
            position: fixed;
            top: .86rem;
            left: 6.6rem;
        }
        .writebg {
            width: 6.7rem;
            height: calc(100vh - 2.8rem);
            overflow-y: scroll;
            background: #FFFFFF;
            border-radius: .6rem .6rem 0 0;
            padding: .12rem .4rem;
            position: fixed;
            bottom: 0;
            left: 0;
            text-align: left;
            .title1 {
                width: 6.5rem;
                line-height: .8rem;
                background: #EEEFEF;
                font-size: .3rem;
                color: #333333;
                font-weight: bold;
                padding-left: .2rem;
                margin-top: .36rem;
            }
            .title2 {
                font-size: .28rem;
                color: #333435;
                line-height: .64rem;
            }
            .content {
                font-size: .26rem;
                color: #666666;
                line-height: .44rem;
            }
        }
    }
    .part3-1 {
        margin-left: .3rem;
        margin-bottom: .5rem;
        .peocard {
            width: 3.32rem;
            height: 3.72rem;
            background: #FFFFFF;
            box-shadow: 0 .08rem .24rem 0 rgba(0,0,0,0.20);
            border-radius: .12rem;
            margin: 1.34rem .22rem 0 0;
            img {
               width: 2rem; 
               margin: -1.1rem auto 0.06rem;
               display: block;

            }
            .name {
                font-size: .3rem;
                color: #333333;
            }
            .position {
                font-size: .24rem;
                color: #666666;
            }
            .info {
                font-size: .2rem;
                color: #999999;
                width: 2.8rem;
                margin: 0 auto;
            }
        }
    }
}
</style>


